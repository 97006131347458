import { View } from "react-native";
import { Card, makeStyles, useTheme } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";

import LocalizedStrings from "../../helpers/LocalizedStrings";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import StatusBadge from "../../components/ui/StatusBadge";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { MyAccountStackParamList } from "../../navigation/MyAccountStackNavigator";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { FlatGrid } from "react-native-super-grid";

type ScreenProp = NavigationProp<MyAccountStackParamList>;

const ListHeader = () => {
  const textStyles = useTextStyles();
  const styles = useStyles();
  const navigation = useNavigation<ScreenProp>();

  const handleAddNew = () => {
    navigation.navigate("AddOffTime");
  };

  return (
    <View style={styles.container}>
      <Text h3 style={[textStyles.colorDarkGreyBlue]}>
        {LocalizedStrings.screens.offTime.title}
      </Text>
      <Spacing vertical={2} />
      <Text body style={[textStyles.colorGreyBlue]}>
        {LocalizedStrings.screens.offTime.content}
      </Text>

      <Spacing vertical={2} />
      <Button
        title={LocalizedStrings.screens.offTime.addNew}
        containerStyle={styles.buttonAdd}
        onPress={handleAddNew}
      />

      <Spacing vertical={2} />
      <Text h4 style={[textStyles.colorDarkGreyBlue]}>
        {LocalizedStrings.screens.offTime.history}
      </Text>
    </View>
  );
};

const ListFooter = () => <Spacing vertical={5} />;

const Row = ({ label, value }: { label: string; value: string }) => {
  const styles = useStyles();
  return (
    <View style={styles.row}>
      <Text capsSmall style={[{ flex: 0.3 }]}>
        {label}
      </Text>
      <Text bodySmall style={[{ flex: 0.7 }]}>
        {value}
      </Text>
    </View>
  );
};

const ListItem = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const navigation = useNavigation<ScreenProp>();
  const dispatch = useAppDispatch();

  const handleEdit = () => {
    navigation.navigate("EditOffTime", { offtime_id: "123" });
  };

  const handleDelete = () => {
    const modalId = "delete_offtime";
    Alert_show({
      dispatch,
      id: modalId,
      title: LocalizedStrings.screens.offTime.deleteModalTitle,
      content: LocalizedStrings.screens.offTime.deleteModalContent,
      buttons: [
        {
          text: LocalizedStrings.common.delete,
          onPress: () => {
            Alert_close({ dispatch, id: modalId });
          },
          style: "destructive"
        },

        {
          text: LocalizedStrings.common.cancel,
          style: "cancel",
          onPress: () => {
            Alert_close({ dispatch, id: modalId });
          }
        }
      ]
    });
  };

  return (
    <Card>
      <View style={styles.rowSpaceBetween}>
        <StatusBadge text="Upcoming" color="#cc4e5d" />
        <View style={[styles.row, { gap: 20 }]}>
          <TouchableOpacity onPress={handleEdit}>
            <Feather
              name="edit-2"
              color={theme.colors.darkGreyBlue}
              size={24}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={handleDelete}>
            <Feather name="trash-2" color={theme.colors.error} size={24} />
          </TouchableOpacity>
        </View>
      </View>
      <Spacing vertical={2} />
      <Row
        label={LocalizedStrings.screens.offTime.listFrom}
        value={"Monday, January 20th"}
      />
      <Row
        label={LocalizedStrings.screens.offTime.listTo}
        value={"Tuesday, January 27th"}
      />
      <Row
        label={LocalizedStrings.screens.offTime.listAddedOn}
        value={"Friday, January 14th"}
      />
      <Row
        label={LocalizedStrings.screens.offTime.listInfo}
        value={"15 Affected Appointments"}
      />
    </Card>
  );
};

const OffTimeScreen = () => {
  return (
    <ScreenContainer>
      <FlatGrid
        data={[1, 2, 3]}
        renderItem={() => <ListItem />}
        ListHeaderComponent={ListHeader}
        ListFooterComponent={ListFooter}
        itemDimension={300}
        /*refreshControl={
          <RefreshControl refreshing={isFetching} onRefresh={handleRefresh} />
        }*/
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20,
      paddingTop: 20
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    rowSpaceBetween: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    buttonAdd: {
      maxWidth: 400
    }
  };
});

export default OffTimeScreen;
